<template>
	<div class="text-right">
        {{finalPrice}} 
    </div>
</template>

<script>
import Common from '@/assets/js/common.js'

export default {
	name: 'PriceFormat',
	props: {
		price: [String, Number],
		currency: String,
        pathArr: Object,
		symbol: Boolean,
	},
	data() {
        return {
            finalPrice : 0,
        }
    },

	mounted() {
		this.FormatPrice()
	},
	methods: {

        async FormatPrice(){
            const formatCurrency= this.getNestedObjectString(this.pathArr, this.currency)
            this.finalPrice = await this.priceFormat(this.price, formatCurrency, this.symbol)
        },
	},
	watch: {
		price(){
			this.FormatPrice()
		}
	}
}
</script>
